import React from 'react'
import { object } from 'prop-types'
import { SectionHeader, SectionWrapper } from '@components/store-details/styles'

const StoreDescription = ({ description }) => {
  if (description) {
    return (
      <SectionWrapper>
        <SectionHeader>Store Description:</SectionHeader>
        <span className="description" dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
      </SectionWrapper>
    )
  }
  return null
}

StoreDescription.propTypes = {
  description: object,
}

export default StoreDescription

import React from 'react'
import { object } from 'prop-types'
import { LocationInformationItemHeader, LocationInformationItemWrapper } from '@components/store-details/styles'
import map from '@images/map.svg'

const StoreAddress = ({ storeInfoFromFeed, store }) => {
  const { address1, address2, city, state, zip } = storeInfoFromFeed || {}
  const isAddressFromFeedValid = address1 && city && state && zip
  const addressFromFeed = `${address1}${address2 ? `, ${address2}` : ''}, ${city}, ${state} ${zip}`
  return (
    <LocationInformationItemWrapper>
      <img alt="pin" src={map} />
      <div>
        <LocationInformationItemHeader>ADDRESS: </LocationInformationItemHeader>
        {isAddressFromFeedValid ? addressFromFeed : store.Address1}
      </div>
    </LocationInformationItemWrapper>
  )
}

StoreAddress.propTypes = {
  store: object,
  storeInfoFromFeed: object,
}

export default StoreAddress

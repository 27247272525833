import React from 'react'
import { object } from 'prop-types'
import { getStandardTime, months, weekdays } from '@helpers/string-helper'
import { LocationInformationItemHeader, LocationInformationItemWrapper } from '@components/store-details/styles'
import clock from '@images/clock.svg'

const getTodayOpen = store => {
  const currentDay = weekdays[new Date().getDay()]
  const month = months[new Date().getMonth()].substr(0, 3)
  const day = new Date().getDate()
  const storeOpen = store.store_hours[`${currentDay.toLowerCase()}Open`]
  const storeClose = store.store_hours[`${currentDay.toLowerCase()}Closed`]
  if (storeOpen && storeClose) {
    return (
      <>
        {`${currentDay}, ${month} ${day} | `}
        <span>Open</span>
        <span>{storeOpen ? ` from ${getStandardTime(storeOpen)} - ${getStandardTime(storeClose)}` : 'Closed'}</span>
      </>
    )
  }
  return `${currentDay}, ${month} ${day}`
}

const StoreHoursToday = ({ storeInfoFromFeed }) => {
  if (storeInfoFromFeed) {
    return (
      <LocationInformationItemWrapper>
        <img alt="hours" src={clock} />
        <div>
          <LocationInformationItemHeader>TODAY: </LocationInformationItemHeader>
          <span className="regularClosing">{storeInfoFromFeed ? getTodayOpen(storeInfoFromFeed) : ''}</span>
        </div>
      </LocationInformationItemWrapper>
    )
  }
  return null
}

StoreHoursToday.propTypes = {
  storeInfoFromFeed: object,
}

export default StoreHoursToday

import React from 'react'
import { object } from 'prop-types'
import StoreAddress from '@components/store-details/store-address'
import StorePhone from '@components/store-details/store-phone'
import StoreHoursToday from '@components/store-details/store-hours-today'
import { LocationInformation } from '@components/store-details/styles'

const StoreLocationInfo = ({ store, storeInfoFromFeed, children }) => (
  <LocationInformation>
    <StoreAddress storeInfoFromFeed={storeInfoFromFeed} store={store} />
    <StoreHoursToday storeInfoFromFeed={storeInfoFromFeed} />
    <StorePhone store={store} storeInfoFromFeed={storeInfoFromFeed} />
    {children}
  </LocationInformation>
)

StoreLocationInfo.propTypes = {
  store: object,
  storeInfoFromFeed: object,
  children: object,
}

export default StoreLocationInfo

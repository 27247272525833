import React from 'react'
import { object } from 'prop-types'
import { FaqWrapper, SectionHeader } from '@components/store-details/styles'

const StoreFaqs = ({ faqs }) => {
  if (faqs?.Markdown) {
    return (
      <FaqWrapper>
        <SectionHeader>FAQs:</SectionHeader>
        <span className="faqs" dangerouslySetInnerHTML={{ __html: faqs.Markdown.Markdown.childMarkdownRemark.html }} />
      </FaqWrapper>
    )
  }
  return null
}

StoreFaqs.propTypes = {
  faqs: object,
}

export default StoreFaqs

import React, { useState, useEffect } from 'react'
import { object } from 'prop-types'
import InStoreScheduleModal from '@shared/modals/InStoreSchedule'
import { getURLParam } from '@helpers/general'
import { cancelStoreAppointment } from '@services/stores'
import { InStoreAppointmentWrapper } from '@components/store-details/styles'

const InStoreAppointment = ({ store }) => {
  const { InStoreAppointments, State, StoreNumber, StoreType } = store
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [isReschedule, setIsReschedule] = useState(false)

  useEffect(() => {
    const utm_campaign = getURLParam('utm_campaign')
    if (utm_campaign === 'cancel-reschedule') {
      cancelAppointment()
    }
  }, [])

  const onOpenModal = storeId => {
    window.dataLayer.push({ event: 'schedule_visit_start', storeId })
    setShowScheduleModal(true)
  }

  const cancelAppointment = () => {
    setShowScheduleModal(true)
    setIsReschedule(true)
    const appointmentId = getURLParam('appointmentId')
    const storeId = getURLParam('storeId')
    const appointmentTime = getURLParam('appointmentTime')
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'schedule_visit_cancel',
        storeId,
        appointmentTime,
      })
    }
    cancelStoreAppointment(appointmentId)
  }

  if (StoreType !== 'Outlet' && InStoreAppointments) {
    return (
      <InStoreAppointmentWrapper>
        <span
          role="button"
          tabIndex="0"
          onClick={() => onOpenModal(StoreNumber)}
          onKeyDown={event => {
            if (event.keyCode === 13) setShowScheduleModal(true)
          }}
        >
          Schedule a Showroom Visit
        </span>
        <InStoreScheduleModal
          shouldShowModal={showScheduleModal}
          store={store}
          closeModal={() => setShowScheduleModal(false)}
          isReschedule={isReschedule}
        />
      </InStoreAppointmentWrapper>
    )
  }
  return null
}

InStoreAppointment.propTypes = {
  store: object,
}

export default InStoreAppointment

import React, { Component } from 'react'
import { objectOf, any, number } from 'prop-types'
import { Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import { getStoreHours, getStoreName, getStoreInfoFromFeed } from '@helpers/store-locator'
import getStorePhone from '@helpers/getStorePhone'
import '@assets/css/pages/store-location.sass'
import StoreInfo from '../store-locator/store-info'
import StyledGoogleMap from './styles'
import Spinner from './spinner'

export class GoogleMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedMarker: {},
      isLoaded: false,
    }
  }

  componentDidMount() {
    this.setState({ isLoaded: true })
  }

  handleLocationClick = (props, marker) => {
    const { pageContext } = this.props
    const store = pageContext
    const storeInfoFromFeed = getStoreInfoFromFeed(store.StoreNumber)
    const fullMarker = {
      title: store.Address1,
      name: getStoreName(store),
      lat: store.Location.latLng.lat,
      lng: store.Location.latLng.lng,
      city: store.City,
      state: store.State,
      storeNumber: store.StoreNumber || store.storeNumber,
      StoreNumber: store.StoreNumber || store.storeNumber,
      zip: store.Zip,
      phone: getStorePhone(storeInfoFromFeed, store),
      hours: storeInfoFromFeed ? getStoreHours(storeInfoFromFeed.store_hours) : '',
    }
    this.setState({
      selectedMarker: fullMarker,
      activeMarker: marker,
      showingInfoWindow: true,
    })
  }

  onMapClicked = () => {
    const { showingInfoWindow } = this.state
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    const { store, google, zoom } = this.props
    const { activeMarker, showingInfoWindow, selectedMarker, isLoaded } = this.state
    return (
      isLoaded && (
        <StyledGoogleMap
          initialCenter={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
          center={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
          className="map"
          google={google}
          zoom={zoom}
          onClick={this.onMapClicked}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
        >
          <Marker
            position={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
            title={`${store.City}${store.StoreName ? ` - ${store.StoreName}` : ''} ${store.StoreType}`}
            icon={{
              url: `https://maps.google.com/mapfiles/markerA.png`,
            }}
            onClick={this.handleLocationClick}
          />
          <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
            <StoreInfo selectedMarker={selectedMarker} />
          </InfoWindow>
        </StyledGoogleMap>
      )
    )
  }
}

GoogleMap.propTypes = {
  pageContext: objectOf(any),
  store: objectOf(any),
  google: objectOf(any),
  zoom: number,
}

export default GoogleApiWrapper({
  apiKey: `${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`,
  LoadingContainer: Spinner,
})(GoogleMap)

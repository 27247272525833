import React from 'react'
import { object, string } from 'prop-types'
import { SectionWrapper, StoreDetailsImage } from '@components/store-details/styles'

const StoreImage = ({ storeImage, storeTypeTitle }) => {
  if (storeImage) {
    return (
      <SectionWrapper>
        <div className="store-image-container">
          <StoreDetailsImage className="store-image" alt={storeTypeTitle} src={storeImage.url} />
        </div>
      </SectionWrapper>
    )
  }
  return null
}

StoreImage.propTypes = {
  storeImage: object,
  storeTypeTitle: string,
}

export default StoreImage
